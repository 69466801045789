<aside class="sidebar sidebar--action" [ngClass]="active ? 'active' : ''">
    <a class="sidebar__close" (click)="closeSidebar()">
        <i class="icon icon--close"></i>
    </a>

    <ul class="sidebar__content">
        <li class="sidebar__item-link">
            <a
                class="sidebar__link-edit tooltip-custom"
                (click)="publishInstance()"
            >
                <i class="icon icon--publish"></i>
                <div class="tooltip__info tooltip__info--left">
                    <p>{{ "studio.publish.button.publish" | translate }}</p>
                </div>
            </a>
        </li>

        <li class="sidebar__item-link">
            <a
                class="sidebar__link-edit tooltip-custom"
                (click)="cloneInstance()"
            >
                <i class="icon icon--duplicate"></i>
                <div class="tooltip__info tooltip__info--left">
                    <p>{{ "studio.common.button.clone" | translate }}</p>
                </div>
            </a>
        </li>

        <li
            *ngIf="
                !company.permissions?.companyLockSession &&
                permissions.realignmentFeature
            "
            class="sidebar__item-link"
        >
            <!-- <li class="sidebar__item-link"> -->
            <a
                class="sidebar__link-edit tooltip-custom"
                (click)="realignInstance()"
            >
                <i class="icon icon--reset"></i>

                <div class="tooltip__info tooltip__info--left">
                    <p>{{ "studio.topbar.reset-instance" | translate }}</p>
                </div>
            </a>
        </li>

        <li
            *ngIf="company.permissions?.companyLockSession"
            class="sidebar__item-link"
        >
            <a
                class="sidebar__link-edit tooltip-custom"
                (click)="unpublishInstance()"
            >
                <i
                    class="icon icon--publish"
                    style="transform: rotateX(180deg)"
                ></i>

                <div class="tooltip__info tooltip__info--left">
                    <p>{{ "studio.instance.create-unpublish" | translate }}</p>
                </div>
            </a>
        </li>

        <li class="sidebar__item-link" *ngIf="!disableDeleteButton">
            <a
                class="sidebar__link-edit tooltip-custom"
                (click)="deleteInstance()"
            >
                <i class="icon icon--delete"></i>
                <div class="tooltip__info tooltip__info--left">
                    <p>{{ "words.delete" | translate }}</p>
                </div>
            </a>
        </li>
    </ul>
</aside>

<div class="templab-library__body templab-library__body--padding">
    <div class="templab-library__filter templab-library__filter--multiple">
        <div class="templab-library__search" *ngIf="!mySelection">
            <form class="templab-form-search templab-form-search--studio">
                <div class="templab-form-search__input">
                    <i class="templab-form-search__icon icon templab-icon-search"></i>

                    <input autocomplete="off" name="search" type="search" [placeholder]="'words.search' | translate" [(ngModel)]="query" [debounce]="800" (onDebounce)="searchContents()">
                </div>
            </form>
        </div>

        <div class="templab-library__switch" *ngIf="selectedContents.length > 0 && !replaceMode">
            <label class="switch__input">
                <div class="switch switch_height_small" [ngClass]="{'switch_active': mySelection}">
                    <input type="checkbox" [(ngModel)]="mySelection" (ngModelChange)="showMySelection()">
                </div>

                <p>{{ 'words.my-selection' | translate }}</p>
            </label>
        </div>

        <div class="templab-library__reset" *ngIf="filtersActive && !mySelection">
            <button class="templab-btn templab-btn--secondary" (click)="clearFilters()">
                {{ 'words.reset-filters' | translate }}
            </button>
        </div>
    </div>

    <div class="templab-library__filter templab-library__filter--multiple" *ngIf="!mySelection">
        <div class="dropdown-simple" [ngClass]="{'active': displays.contents}" (click)="displayed('contents')" *ngIf="availableContentTypes">
            <div class="dropdown-simple__title">
                <span>{{ 'words.types' | translate }}</span>

                <i class="templab-icon templab-icon-arrow-ios-down"></i>
            </div>

            <div class="dropdown-simple__content" (click)="stopPropagation($event)">
                <div class="content-list">
                    <div *ngFor="let content of availableContentTypes">
                        <div class="content-item">
                            <label class="templab-checkbox" [ngClass]="{'templab-checkbox--intermediate': isIntermediate(content.key)}">
                                <input type="checkbox" class="templab-checkbox__input" [checked]="isSelectedTypes('content', content.key)" (click)="selectType('content', content.key)">
                                <span class="templab-checkbox__checkmark"></span>
                            </label>

                            <span class="item-label">{{ content.translate }}</span>
                        </div>

                        <div class="sub-list" *ngIf="content.key === 'course' && isSelectedTypes('content', 'course')">
                            <div class="sub-item" *ngFor="let type of availableCourseTypes | key">
                                <label class="templab-checkbox">
                                    <input type="checkbox" class="templab-checkbox__input" [checked]="isSelectedTypes('course', type.key)" (click)="selectType('course', type.key)">
                                    <span class="templab-checkbox__checkmark"></span>
                                </label>

                                <span class="item-label">{{ 'words.course-type.' + type.key | translate }}</span>
                            </div>
                        </div>

                        <div class="sub-list" *ngIf="content.key === 'externalContent' && isSelectedTypes('content', 'externalContent')">
                            <div class="sub-item" *ngFor="let type of availableExternalContentTypes | key">
                                <label class="templab-checkbox">
                                    <input type="checkbox" class="templab-checkbox__input" [checked]="isSelectedTypes('externalContent', type.key)" (click)="selectType('externalContent', type.key)">
                                    <span class="templab-checkbox__checkmark"></span>
                                </label>

                                <span class="item-label">{{ 'filter.content-type.' + type.key | translate }}</span>
                            </div>
                        </div>

                        <div class="sub-list" *ngIf="content.key === 'externalResource' && isSelectedTypes('content', 'externalResource')">
                            <div class="sub-item" *ngFor="let type of availableExternalResourceTypes | key">
                                <label class="templab-checkbox">
                                    <input type="checkbox" class="templab-checkbox__input" [checked]="isSelectedTypes('externalResource', type.key)" (click)="selectType('externalResource', type.key)">
                                    <span class="templab-checkbox__checkmark"></span>
                                </label>

                                <span class="item-label">{{ 'filter.content-type.' + type.key | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown-simple__actions">
                    <button class="templab-btn templab-btn--tertiary" (click)="displayed('contents')">
                        {{ 'studio.common.button.cancel' | translate }}
                    </button>

                    <button class="templab-btn templab-btn--secondary" (click)="saveFilters('contents')">
                        {{ 'words.validate' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="dropdown-simple" [ngClass]="{'active': displays.providers}" (click)="displayed('providers')" *ngIf="availableProviders">
            <div class="dropdown-simple__title">
                <span>{{ 'words.providers' | translate }}</span>

                <i class="templab-icon templab-icon-arrow-ios-down"></i>
            </div>

            <div class="dropdown-simple__content" (click)="stopPropagation($event)">
                <div class="content-list">
                    <div class="content-item" *ngFor="let provider of availableProviders">
                        <label class="templab-checkbox">
                            <input type="checkbox" class="templab-checkbox__input" [checked]="isSelectedProvider(provider.id)" (click)="selectProvider(provider.id)">
                            <span class="templab-checkbox__checkmark"></span>
                        </label>

                        <span class="item-label">{{ provider.translate }}</span>
                    </div>
                </div>

                <div class="dropdown-simple__actions">
                    <button class="templab-btn templab-btn--tertiary" (click)="displayed('providers')">
                        {{ 'studio.common.button.cancel' | translate }}
                    </button>

                    <button class="templab-btn templab-btn--secondary" (click)="saveFilters('providers')">
                        {{ 'words.validate' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="dropdown-simple" [ngClass]="{'active': displays.languages}" (click)="displayed('languages')" *ngIf="availableLanguages">
            <div class="dropdown-simple__title">
                <span>{{ 'words.language' | translate }}</span>

                <i class="templab-icon templab-icon-arrow-ios-down"></i>
            </div>

            <div class="dropdown-simple__content" (click)="stopPropagation($event)">
                <div class="content-list">
                    <div class="content-item" *ngFor="let language of availableLanguages">
                        <label class="templab-checkbox">
                            <input type="checkbox" class="templab-checkbox__input" [checked]="isSelectedLanguage(language.id)" (click)="selectLanguage(language.id)">
                            <span class="templab-checkbox__checkmark"></span>
                        </label>

                        <span class="item-label">{{ language.name }}</span>
                    </div>
                </div>

                <div class="dropdown-simple__actions">
                    <button class="templab-btn templab-btn--tertiary" (click)="displayed('languages')">
                        {{ 'studio.common.button.cancel' | translate }}
                    </button>

                    <button class="templab-btn templab-btn--secondary" (click)="saveFilters('languages')">
                        {{ 'words.validate' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="dropdown-simple dropdown-simple--overflow" [ngClass]="{'active': displays.skills}" (click)="displayed('skills')" *ngIf="availableSkills">
            <div class="dropdown-simple__title">
                <span>{{ 'words.skills' | translate }}</span>

                <i class="templab-icon templab-icon-arrow-ios-down"></i>
            </div>

            <div class="dropdown-simple__content" (click)="stopPropagation($event)">
                <div class="content-list">
                    <div class="content-item" *ngFor="let skill of availableSkills">
                        <label class="templab-checkbox">
                            <input type="checkbox" class="templab-checkbox__input" [checked]="isSelectedSkill(skill.id)" (click)="selectSkill(skill.id)">
                            <span class="templab-checkbox__checkmark"></span>
                        </label>

                        <span class="item-label">{{ skill.name }}</span>
                    </div>
                </div>

                <div class="dropdown-simple__actions">
                    <button class="templab-btn templab-btn--tertiary" (click)="displayed('skills')">
                        {{ 'studio.common.button.cancel' | translate }}
                    </button>

                    <button class="templab-btn templab-btn--secondary" (click)="saveFilters('skills')">
                        {{ 'words.validate' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="templab-library__filter templab-library__filter--multiple" *ngIf="!mySelection">
        <div class="templab-selector" [ngClass]="{'active': displays.sort}" (click)="displayed('sort')">
            <div class="templab-selector__content">
                {{ 'filter.sorted-by' | translate }} : {{ getOrderDate() | translate }}
            </div>

            <div class="templab-selector__icon">
                <i class="templab-icon templab-icon-arrow-ios-down"></i>
            </div>

            <div class="templab-selector__dropdown" [ngClass]="{'active': displays.sort}" >
                <ul>
                    <li (click)="selectOrderDate('-date')">
                        <span>{{ 'words.addition-date' | translate }}</span>
                    </li>

                    <li (click)="selectOrderDate('title')">
                        <span>A-Z</span>
                    </li>

                    <li (click)="selectOrderDate('-title')">
                        <span>Z-A</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="templab-library-content__container">
        <div class="templab-library-content__contents">
            <h3>{{ 'words.my-contents' | translate }}</h3>

            <msc-loader [loading]="loaders.contents"></msc-loader>

            <div class="templab-library-content__contents__list" *ngIf="!loaders.contents">
                <div class="templab-library-content__empty" *ngIf="contents?.length === 0">
                    {{ 'words.no.courses.found' | translate }}
                </div>

                <div class="templab-library-content__contents__card card" *ngFor="let content of contents" [ngClass]="{'selected' : isContentSelected(content)}" (click)="selectContent(content)">
                    <span class="card__checkmark" *ngIf="isContentSelected(content)">
                        <i class="templab-icon templab-icon-checkmark-1"></i>
                    </span>

                    <div class="card__banner" *ngIf="getLogo(content.data)">
                        <div [style.backgroundImage]="getLogo(content.data)"></div>
                    </div>

                    <div class="card__tag" [ngClass]="getColor(content)" *ngIf="!content.data.provider">
                        <span class="card__tag__text">{{ getType(content) | translate }}</span>
                    </div>

                    <div class="card__image">
                        <div *ngIf="!content.data.picture" [ngStyle]="{'background-image': 'url(' + 'https://static.myskillcamp.com/lxp/images/logo-empty-state-' + content.data.id % 7 + '.jpg)'}"></div>
                        <div *ngIf="content.data.picture" [ngStyle]="{'background-image': 'url(' + content.data.picture + ((content.data?.provider?.key !== 'linkedin') ? '?size=512' : '') + ')'}"></div>
                    </div>

                    <div class="card__infos">
                        <span class="card__infos__duration" *ngIf="content.data.duration">
                            <i class="icon templab-icon-clock"></i>
                            {{ content.data.duration | duration }}
                        </span>

                        <h4>{{ content.data.title | truncate: '20' }}</h4>

                        <p>{{ content.data.description | truncate: '50' }}</p>

                        <span class="card__infos__instances-count" *ngIf="content.data.instancesCount">
                            {{ content.data.instancesCount }}
                            <i class="icon templab-icon-content"></i>
                        </span>
                    </div>
                </div>
            </div>

            <button class="show-more" *ngIf="pagination?.currentPage < pagination?.lastPage && !loaders.contents && !mySelection" (click)="loadMoreContent()" [disabled]="loaders.loadMoreContents">
                <msc-loader [loading]="loaders.loadMoreContents"></msc-loader>

                <span *ngIf="!loaders.loadMoreContents">{{ 'words.load-more' | translate }}</span>
            </button>
        </div>
    </div>
</div>

<div class="templab-library__footer">
    <p class="templab-library-content__footer__selected-contents">
        <i class="templab-icon templab-icon-eye"></i>
        {{ selectedContents.length }} {{ (selectedContents.length > 1 ? 'words.selected-contents' : 'words.selected-content') | translate | lowercase }}
    </p>

    <button class="templab-btn templab-btn--secondary" (click)="save()" *ngIf="!replaceMode">
        <i class="templab-icon templab-icon-checkmark"></i>
        {{ 'words.validate' | translate }}
    </button>

    <button class="templab-btn templab-btn--secondary" (click)="edit()" *ngIf="replaceMode">
        <i class="templab-icon templab-icon-checkmark"></i>
        {{ 'words.edit' | translate }}
    </button>
</div>

import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
//
import { Subscription } from 'rxjs';
//
import { Journey, JourneyProvider, SkillProvider, UserProvider, SectionContent, ExternalLinkProvider } from '@stuplay';
//
import { ToastService } from '../../../utils/components/toast/toast.service';
import { StorageService } from '../../../utils/services/storage.service';
import { EmitterService } from '../../../utils/services/emitter.service';
import { environment } from 'src/environments/environment';

const DATE_DEFAULT_FORMAT: string = 'YYYY/MM/dd HH:mm:ss';

@Component({
    selector: 'app-journey-preview',
    templateUrl: './journey-preview.component.html',
    styleUrls: ['./journey-preview.component.less'],
    providers: [DatePipe],
})

export class JourneyPreviewComponent {
    public subscription: Subscription;
    public selectedContent: any;
    public skillsLevel: number[][] = [];
    public me: any;
    public company: any;
    public journey: Journey;
    public sidebarActive: string = '';
    public selectedContentForEdit: any;
    public prefixDuration: string = 'day';
    public mode: string = 'summary';
    public libraryAction: string;
    public movingContent: boolean = false;
    public displayDropdown: boolean = false;
    public displayAside: boolean = false;
    public skills: any = [];
    public selectedCompany: any;
    public companies: any;
    public loading: boolean = false;
    public createdEvent: any;
    public sectionContent: any;
    public error: any;
    public addingContent: boolean = false;
    public addingContentType: string = '';
    public edit: boolean = false;
    public loaders: any = {
        createEvent: false
    };

    constructor(
        private route: ActivatedRoute,
        private journeyProvider: JourneyProvider,
        private toastService: ToastService,
        private storageService: StorageService,
        private skillProvider: SkillProvider,
        private userProvider: UserProvider,
        private http: HttpClient,
        private emitterService: EmitterService,
        private externalLinkProvider: ExternalLinkProvider,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        this.subscription = this.route.params.subscribe(() => {
            this.journey = this.route.snapshot.data.journey;
        });

        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');
        this.skillsLevel[0] = [];
        this.skillsLevel[1] = [];
        this.getCompanies();

        this.emitterService.refreshCondition.subscribe((data) => {
            if (data) {
                this.refreshContent();
            }
        })
    }

    displayedDropdown(): void {
        this.displayDropdown = !this.displayDropdown;
    }

    getSkills(): void {
        this.loading = true;
        const params = {
            context: 'traject',
            context_id: this.journey.id,
            company_id: this.selectedCompany.slug,
        };

        this.skillProvider.getCompanySkills(params).subscribe((data) => {
            this.skills = data.skills;
            this.loading = false;
        });
    }

    getCompanies(): void {
        const params = {
            role: 'company_author'
        };

        this.userProvider.getCompanies(params).subscribe((data) => {
            this.companies = data;

            this.selectedCompany = this.companies.find((company: any) => {
                return company.slug === this.company.slug;
            });

            this.getSkills();
        });
    }

    filterSkills(thematic?: boolean): any {
        return this.skills.filter((skill: any) => {
            if (thematic && skill.thematic) {
                return skill;
            }

            if (!thematic && !skill.thematic) {
                return skill;
            }
        });
    }

    updateLevel(skill: any, level: number): void {
        skill.level = level;
        const params = {
            context: 'traject',
            context_id: this.journey.id,
            level
        };
        this.skillProvider.updateLevel(skill.id, params).subscribe();
    }

    attachSkill(skill: any, level: number): void {
        const params = {
            context: 'traject',
            context_id: this.journey.id,
            company_id: this.selectedCompany.slug,
            level
        };

        this.skillProvider.attach(skill.id, params).subscribe(() => {
            skill.level = level;
            this.skills.push(skill);
        });
    }

    detachSkill(skill: any): void {
        const params = {
            context: 'traject',
            context_id: this.journey.id
        };

        this.skillProvider.detach(skill.id, params).subscribe(() => {
            const index = this.skills.findIndex((data: any) => {
                return data.id === skill.id;
            });

            if (index !== -1) {
                this.skills.splice(index, 1);
            }
        });
    }

    isLevel(index: number, skill: any, levelExpected: number, thematic?: boolean): boolean {
        if (this.skillsLevel[thematic ? 0 : 1][index]) {
            return this.skillsLevel[thematic ? 0 : 1][index] > levelExpected;
        }
        return skill.level > levelExpected;
    }

    displayLevel(index: number, level?: number, thematic?: boolean): void {
        this.skillsLevel[thematic ? 0 : 1][index] = level;
    }

    eventDispatcher(event: any): void {
        switch (event.type) {
            case 'attach':
                this.attachSkill(event.skill, event.level);
                break;
            case 'detach':
                this.detachSkill(event.skill);
                break;
            case 'level':
                this.updateLevel(event.skill, event.level);
                break;
        }
    }

    openAside(): void {
        this.displayAside = true;
    }

    closeAside(): void {
        this.displayAside = false;
    }

    selectCompany(company: any): void {
        if (this.selectedCompany.slug !== company.slug) {
            this.skills = [];
            this.selectedCompany = company;
            this.getSkills();
        }
    }

    getPicture(media: any): string {
        if (media.storage === 'ext') {
            return media.url;
        }

        return media.pictureUrl + '?size=1024';
    }

    resetContent(): void {
        this.selectedContent = null;
        this.selectedContentForEdit = null;
    }

    transformDuration(time: number): number {
        let duration = time;

        if (duration % 60 === 0) {
            duration /= 60;
            this.prefixDuration = 'min'
        }

        if (duration % 60 === 0) {
            duration /= 60;
            this.prefixDuration = 'hour';
        }

        if (duration % 24 === 0) {
            duration /= 24;
            this.prefixDuration = 'day';
        }

        return duration;
    }

    openSidebar(type: string): void {
        this.resetContent();
        this.sidebarActive = type;
    }

    closeSidebar(): void {
        this.sidebarActive = '';
        this.mode = 'summary';
    }

    updateJourney(journey: any): void {
        this.journey = journey;
        this.sortContent(journey);
        this.closeSidebar();
        this.toastService.push('journey-updated');
    }

    manageConditionSidebar(active: boolean): void {
        this.sidebarActive = (active) ? 'condition' : '';
    }

    manageAddCondition(events: any, content: any): void {
        for (const event of events) {
            this.journeyProvider.addCondition(this.journey.id, content.id, event).subscribe((condition) => {
                const contentFound = this.journey.contents.find((data) => {
                    return data.id === content.id;
                });

                if (contentFound) {
                    if (contentFound.conditions) {
                        contentFound.conditions.push(condition);
                    } else {
                        contentFound.conditions = [condition];
                    }

                    this.emitterService.refreshCondition.emit(true);
                }
            });
        }
    }

    manageUpdateCondition(events: any, content: any): void {
        for (const event of events) {
            this.journeyProvider.updateCondition(this.journey.id, content.id, event).subscribe((condition) => {
                const index = this.journey.contents.findIndex((data) => {
                    return data.id === content.id;
                });

                if (index !== -1) {
                    const conditionIndex = this.journey.contents[index].conditions.findIndex((data: any) => {
                        return data.id === event.id;
                    });

                    this.journey.contents[index].conditions[conditionIndex] = condition;
                    this.emitterService.refreshCondition.emit(true);
                }
            });
        }
    }

    manageDeleteCondition(events: any, content: any): void {
        for (const event of events) {
            this.journeyProvider.deleteCondition(this.journey.id, content.id, event.id).subscribe(() => {
                const index = this.journey.contents.findIndex((data) => {
                    return data.id === content.id;
                });

                if (index !== -1) {
                    const conditionIndex = this.journey.contents[index].conditions.findIndex((data: any) => {
                        return data.id === event.id;
                    });

                    this.journey.contents[index].conditions.splice(conditionIndex, 1);
                    this.emitterService.refreshCondition.emit(true);
                }
            });
        }
    }

    moveContent(up: boolean, index: number): void {
        this.movingContent = true;
        const newPosition = up ? --this.journey.contents[index].position : ++this.journey.contents[index].position;

        this.journeyProvider.updateContentPosition(this.journey.id, this.journey.contents[index].id, newPosition).subscribe(data => {
            this.movingContent = false;
            this.sortContent(data);
        });
    }

    addContent(context: string, contents: any): void {
        const newContents = [];
        for (const content of contents) {
            newContents.push({
                context: context === 'course' ? content.data.type === 'external_resource' ? 'external_content' : content.data.type : context,
                contextId: context === 'course' ? content.data.id : content.id
            });
        }

        const params = {
            contents: newContents,
            companyId: this.company.id
        };

        this.journeyProvider.addContent(this.journey.id, params).subscribe((data) => {
            if (this.journey.contents) {
                this.journey.contents = this.journey.contents.concat(data);
            } else {
                this.journey.contents = data;
            }
            if (context === 'event') {
                this.updateEvent(this.sectionContent);
            }

            this.closeSidebar();
        });
    }

    changeContent(content: any): void {
        this.journeyProvider.changeContent(this.journey.id, this.selectedContentForEdit.value.id, content.type === 'course_template' ? 'course' : content.type, content.data.id, this.company.id).subscribe(() => {
            this.refreshContent();
        });
    }

    editContent(context: string, event: any): void {
        this.resetContent();
        this.selectedContentForEdit = event;

        if (context === 'external_content') {
            this.openLibrary('link', true);
        } else if (context === 'content' && event.value.context === 'event') {
            this.openLibrary('event', true);
        } else {
            event.value.context === 'external_link' ? this.sidebarActive = 'external-link' : this.openLibrary('activity', true);
        }
    }

    flushContentForEdit(): void {
        this.selectedContentForEdit = null;
    }

    updateEvent(sectionContent: any): void {
        sectionContent.event.eventSessions[0].startAt = this.datePipe.transform(new Date(sectionContent.event.eventSessions[0].startAt), DATE_DEFAULT_FORMAT);
        sectionContent.event.eventSessions[0].endAt = this.datePipe.transform(new Date(sectionContent.event.eventSessions[0].endAt), DATE_DEFAULT_FORMAT);
        sectionContent.event.eventSessions[0].companyId = this.company.id;
        sectionContent.event.eventSessions[0].update()
            .subscribe({
                next: () => this.error = null,
                error: (error: any) => this.error = error
            });

        sectionContent.event.update()
            .subscribe({
                next: () => {
                    if (sectionContent.event.eventSessions[0].length > 0) {
                        sectionContent.event.createOrUpdateWebinar(sectionContent.description, Intl.DateTimeFormat().resolvedOptions().timeZone, true).subscribe();
                    }
                },
                error: (error: any) => this.error = error
            });
    }

    updateContent(type: string, content: any): void {
        this.closeSidebar();

        const index = this.journey.contents.findIndex((object) => {
            if (object.context === type) {
                return object.data.id === content.id;
            }
        });

        if (index !== -1) {
            this.journey.contents[index].data = content;
        }
    }

    deleteContent(content: any): void {
        this.journeyProvider.deleteContent(this.journey.id, content.id).subscribe(() => {
            const index = this.journey.contents.findIndex((data) => {
                if (data.context === content.context) {
                    return data.id === content.id
                }
            });

            if (content.playerContext === 'external_link') {
                this.deleteExternalLink(content.data.externalLink.id);
            }

            if (index !== -1) {
                this.journey.contents.splice(index, 1);
            }
        });

        this.journeyProvider.getContentPosition(this.journey.id).subscribe((data) => {
            this.sortContent(data);
        });
    }

    refreshContent(): void {
        this.changeMode('summary');

        const params = {
            company_id: this.company.id
        };

        this.journeyProvider.getJourney(this.journey.id, params).subscribe((data) => {
            this.journey = data;
        });
    }

    publishJourney(event: any): void {
        this.journeyProvider.publish(this.journey.id, event.settings).subscribe(() => {
            this.toastService.push('journey-published');
            this.journey.published = true;
            this.closeSidebar();
        });
    }

    deleteExternalLink(id: any): void {
        const params = {
            company_id: this.company.id
        }
        this.externalLinkProvider.deleteExternalLink(id, params).subscribe(() => { });
    }

    sortContent(data: any): void {
        if (!this.journey.contents) {
            return;
        }

        for (const id in data) {
            const index = this.journey.contents.findIndex((content) => {
                return content.id === parseInt(id, 10);
            });

            if (index !== -1) {
                this.journey.contents[index].position = data[id];
            }
        }

        this.journey.contents.sort((a, b) => {
            if (a.position > b.position) {
                return 1;
            }

            if (a.position < b.position) {
                return -1;
            }

            return 0;
        });
    }

    addEvent(): void {
        const params = {
            language: this.me.language.code,
            position: 0,
            type: 'place'
        }
        this.loaders.createEvent = true;
        this.http.post(`${environment.envVar.API_URL}/events`, params).subscribe((data) => {
            this.createdEvent = data;

            const sectionParams = {
                context: 'event',
                context_id: this.createdEvent.id,
                position: params.position
            }
            this.http.post(`${environment.envVar.API_URL}/studio/section-content`, sectionParams).subscribe((dt) => {
                this.loaders.createEvent = false;
                this.sectionContent = new SectionContent(dt, this.http);
                this.openLibrary('event');
            })
        })
    }

    updateLti(lti: any): void {
        const params = {
            company_id: this.company.id,
            type: 'lti',
            name: lti.name,
            description: lti.description,
            url: lti.url,
            redirect_url: lti.redirectUrl,
            key: lti.key,
            secret: lti.secret,
            names: lti.names,
            anonymize: lti.anonymize,
            mode: lti.mode,
            mediaId: lti.mediaId
        };

        this.externalLinkProvider.updateExternalLink(lti.id, params).subscribe(() => {
            this.refreshContent()
        });
    }

    openContentManager(type: string): void {
        this.addingContent = true;
        this.addingContentType = type;
    }

    changeMode(mode: string, context?: string): void {
        if (context === 'event') {
            this.addEvent();
        } else {
            this.mode = mode;
        }
    }

    openLibrary(action: string, edit: boolean = false): void {
        if(!edit){
            this.selectedContentForEdit = null;
        }
        this.libraryAction = action;
        this.mode = 'library';
        this.edit = edit;
    }

    manageUpdate(event: any): void {
        if (event.type === 'event') {
            this.updateContent('content', event.data);
        } else if (event.type === 'content') {
            this.changeContent(event.data[0]);
        }
    }
}

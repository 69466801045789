export const environment = {
  name: {
    short: 'dev',
    long: 'dev'
  },
  analytics: {
    google: 'UA-112169318-4'
  },
  production: false,
  tokenName: 'token_msc_staging',
  tokenSessionName: 'token_session',
  refreshTokenName: 'refresh_token_staging',
  unsplashAccess: '06c6f6cddb8970690101c6c2dbc61b4459c029d26195cda8f28d9e6ac9fad7fd',
  unsplashSecret: '95ac1a4c81cfc50a8486f033cf7a05835ab03b8c21957eafc22de93deacd5611',
  domain: 'deltablue.com',
  envVar: {
      API_URL: "https://api-upgraded.myskillcamp.com.deltabluecdn.com/api",
      AUTH_URL: "https://auth.myskillcamp.com.deltabluecdn.com",
      STORAGE_URL: "https://api-upgraded.myskillcamp.com.deltabluecdn.com/api/stockage/",
      APP_URL: "https://app.myskillcamp.com.deltabluecdn.com/",
      STUDIO_URL: "https://studio.myskillcamp.com.deltabluecdn.com/",
      PLAYER_URL: "https://player.myskillcamp.com.deltabluecdn.com/",
      CLOUD_URL: "https://cloud.myskillcamp.com.deltabluecdn.com"
}
};
